<template>
  <div class="kodefast-container">
    <h1 class="kodefast-title">Welcome to Kodefast</h1>
    <p class="kodefast-description">
      Kodefast is a dynamic platform that enables seamless integrations and workflow automations, making your business processes faster and smarter. Discover how Kodefast can transform the way you work!
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  async mounted() {
    this.extractQueryFromUrl();
  },
  methods: {
    async extractQueryFromUrl() {
      let query = this.$route.query;
      localStorage.setItem("extractCode", JSON.stringify(query));
      window.close();
    },
  },
};
</script>

<style scoped>
.kodefast-container {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.kodefast-title {
  font-size: 2rem;
  font-weight: bold;
  color: #2a9d8f;
  margin-bottom: 10px;
}

.kodefast-description {
  font-size: 1rem;
  color: #555555;
  line-height: 1.5;
}
</style>
